<template>
  <div
    v-if="value.show || isManager"
    class="newsItem"
    :style="value.fix === true ? 'background: #FFF5CF; border: 1px solid #FFE297;' : ''">
    <div style="display: flex; flex-direction: row" v-if="isManager">
      <img
        src="@/assets/icons/edit-btn.svg"
        alt="edit"
        class="news-edit"
        @click="textEdit = !textEdit" />
    </div>
    <a
      :href="getUrl(value.stpUrl)"
      @click="clearFakeUser"
      v-if="value.stpUrl !== null && value.stpUrl !== ''"
      class="newsItem__header-url"
      v-html="value.header"></a>
    <p v-else class="newsItem__header" v-html="value.header"></p>
    <div style="display: flex; flex-direction: row">
      <span class="newsItem__date" v-html="value.date"></span>
      <img class="newsItem__date-img" src="@/assets/icons/news-date.svg" alt="date" />
    </div>
    <div class="newsItem__text" v-html="value.text"></div>
    <div
      v-if="value.scanFiles.length > 0"
      style="width: 100%; margin: 25px 0 9px 0"
      :style="
        value.fix === true
          ? 'border-bottom: 1px solid #FFE297;'
          : 'border-bottom: 2px solid #EBEFF2;'
      "></div>
    <NewsFile
      v-for="(item, x) in value.scanFiles"
      :key="x"
      class="newsItem__file"
      :value.sync="item" />
    <!--    <v-dialog-->
    <!--      v-model="deleteConfirm"-->
    <!--      width="380"-->
    <!--    >-->
    <!--      <v-card>-->
    <!--        <v-card-title>Подтверждение удаления новости</v-card-title>-->
    <!--        <v-card-actions style="justify-content: center">-->
    <!--          <v-btn class="dialog-btn" @click="deleteText(value.id)">Да</v-btn>-->
    <!--          <v-btn class="dialog-btn" @click="deleteConfirm = false">Нет</v-btn>-->
    <!--        </v-card-actions>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->
    <div v-if="textEdit">
      <div class="zxczxc__overlay"></div>
      <div class="zxczxc">
        <v-card class="dialog">
          <img
            @click="textEdit = false"
            class="dialog__exit"
            src="@/assets/icons/dialog-exit.png"
            alt="exit" />
          <h2 class="dialog__header">Редактирование новости</h2>
          <div class="dialog__line"></div>
          <div class="dialog__div">
            <label class="dialog__label">Заголовок новости</label>
            <v-text-field v-model="value.header" required></v-text-field>
            <label class="dialog__label">Ссылка заголовка</label>
            <v-text-field v-model="value.stpUrl" required></v-text-field>
            <div style="display: flex; flex-direction: row">
              <div class="dialog__type-input">
                <label class="dialog__label">Тип</label>
                <v-autocomplete clearable v-model="value.type" :items="newsTypes"></v-autocomplete>
              </div>
              <div class="dialog__date-input">
                <label class="dialog__label">Дата</label>
                <vue-ctk-date-time-picker
                  id="DateTimePicker"
                  v-model="value.date"
                  :no-button-now="true"
                  only-date
                  format="YYYY-MM-DD"
                  formatted="LL"
                  label=""
                  class="dialog__date" />
              </div>
              <v-checkbox
                off-icon
                v-model="value.show"
                label="Показывать новость"
                style="margin-right: 43px"></v-checkbox>
              <v-checkbox off-icon v-model="value.fix" label="Закрепить новость"></v-checkbox>
            </div>
            <div>
              <label class="dialog__label">Текст новости</label>
              <CkEditor class="dialog__text" :edit-data-prop.sync="value.text" />
            </div>
            <v-autocomplete
              v-model="orgFounders"
              :items="founderSpr"
              multiple
              label="Указать для кого предназначена новость"></v-autocomplete>
            <div class="dialog__files">
              <label class="dialog__label">Приложенные файлы</label>
              <div
                v-for="(file, y) in value.scanFiles"
                :key="y"
                style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row; margin-right: 70px">
                  <v-text-field
                    style="width: 500px; margin-right: 15px"
                    v-model="file.fileTitle"
                    required></v-text-field>
                  <v-file-input
                    prepend-icon="mdi-folder"
                    v-model="file.fileToUpload"
                    accept="application/pdf, .doc, .docx, .xlsx, .xls"
                    placeholder="Выберите файл">
                  </v-file-input>
                </div>
                <div
                  style="display: flex; flex-direction: row"
                  v-if="!file.fileToUpload && file.articleId">
                  <p class="dialog__files-preview">{{ file.fileNameOrig }}.{{ file.fileExt }}</p>
                  <img
                    class="dialog__files-delete"
                    src="@/assets/icons/close-red-round.svg"
                    alt="delete"
                    @click="deleteFile(file.id)" />
                  <v-btn
                    :disabled="file.sortOrder === 1"
                    @click="moveFile(file.id, 'DOWN')"
                    class="file-move-btn">
                    &#8593;
                  </v-btn>
                  <v-btn
                    :disabled="file.sortOrder === value.scanFiles.length"
                    @click="moveFile(file.id, 'UP')"
                    class="file-move-btn">
                    &#8595;
                  </v-btn>
                </div>
              </div>
              <img
                src="@/assets/icons/add-file-round-blue.svg"
                class="dialog__files-add-input"
                alt="add-file"
                @click="addFileInput" />
            </div>
          </div>
          <div class="dialog__line"></div>
          <v-card-actions>
            <button class="dialog-btn" @click="editText">Сохранить</button>
            <button class="dialog-btn dialog-btn_delete" @click="deleteText(value.id)">
              Удалить
            </button>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import CkEditor from '@/components/UI/vue-ckeditor';
  import axios from 'axios';
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import NewsFile from '@/layouts/newsCMS/NewsFile';

  export default {
    name: 'NewsItem',
    components: { NewsFile, CkEditor, VueCtkDateTimePicker },
    data() {
      return {
        text: null,
        hover: false,
        textEdit: false,
        activated: false,
        newsArray: null,
        orgFounders: [],
        newsTypes: [
          { text: 'Новости для ВУЗов', value: 'VUZ' },
          { text: 'Новости для ФОИВов', value: 'FOIV' },
          { text: 'Новости для ВУЗов и ФОИВов', value: 'ALL' },
        ],
        deleteConfirm: false,
      };
    },
    props: {
      value: {
        type: [Object, Array],
        default: function () {
          return [];
        },
      },
      founderSpr: {
        type: [Object, Array],
        default: function () {
          return [];
        },
      },
    },
    // watch: {
    //   textEdit(val) {
    //     setTimeout(() => {
    //       this.activated = val;
    //     }, 300);
    //   },
    // },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        isManager: 'isManager',
      }),
    },

    created() {
      this.orgFoundersFunc();
    },

    methods: {
      ...mapActions('keycloak', { clearFakeUser: 'clearFakeUser' }),

      async orgFoundersFunc() {
        for (let c = 0; c < this.value.orgFounders.length; c++) {
          this.orgFounders.push(this.value.orgFounders[c].id);
        }
      },

      async editText() {
        const founderArray = [];
        if (this.orgFounders.length > 0) {
          for (let y = 0; y < this.orgFounders.length; y++) {
            founderArray.push(this.founderSpr.filter((el) => el.id === this.orgFounders[y])[0]);
          }
        }
        try {
          const res = await axios.post(
            `/api/cms/set`,
            {
              id: this.value.id,
              text: this.value.text,
              header: this.value.header,
              type: this.value.type,
              fix: this.value.fix,
              show: this.value.show,
              stpUrl: this.value.stpUrl,
              date: this.value.date,
              orgFounders: founderArray,
            },
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            const array = this.value.scanFiles.filter((el) => el.fileToUpload);
            for (let x = 0; x < array.length; x++) {
              if (array[x].fileNameOrig) {
                // удаление всех старых экземпляров, среди тех, у кого при редактировании появилось .fileToUpload
                await this.deleteFile(array[x].id);
              }
              await this.uploadFile(this.value.id, array[x].fileToUpload, array[x].fileTitle);
            }

            for (let x = 0; x < this.value.scanFiles.length; x++) {
              await this.updateFileTitle(
                this.value.scanFiles[x].id,
                this.value.scanFiles[x].fileTitle,
              );
            }

            this.$emit('getAll');
            this.textEdit = false;
            console.log('editText успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async uploadFile(arg, file, fileTitle) {
        const data = new FormData();
        data.append('file', file);
        if (fileTitle === undefined) {
          data.append('fileTitle ', '');
        } else {
          data.append('fileTitle ', fileTitle);
        }
        data.append('scanType', this.value.type);
        data.append('articleId', arg);
        const responseAxios = await axios.put('/api/cms/file/scan/upload_admin_articleId', data, {
          headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
        });
        if (responseAxios.status === 200) {
          console.log('Файл успешно загружен');
        } else {
          console.log('Ошибка загрузки файла');
        }
      },

      async deleteText(id) {
        try {
          const res = await axios.delete(`/api/cms/del?id=${id}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.$emit('getAll');
            this.deleteConfirm = false;
            console.log('deleteText успех');
            this.textEdit = false;
          }
        } catch (e) {
          console.log(e);
        }
      },

      async updateFileTitle(id, title) {
        try {
          const res = await axios.put(
            `/api/cms/file/scan/update?fileId=${id}`,
            {
              fileTitle: title,
            },
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            console.log('updateFileTitle успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async moveFile(id, arg) {
        try {
          const res = await axios.put(`/api/cms/file/scan/move?fileId=${id}&moveOrder=${arg}`, '', {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.value.scanFiles = res.data;
            console.log('moveFile успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async deleteFile(id) {
        try {
          const res = await axios.delete(`/api/cms/file/scan/delete?fileId=${id}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.value.scanFiles = this.value.scanFiles.filter((el) => el.id !== id);
            console.log('deleteFile успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      addFileInput() {
        this.value.scanFiles.push({});
      },

      getUrl(arg) {
        if (arg.includes('//ined.ru') || arg.includes('//old.ined.ru')) {
          // console.log(`${arg}?auth=login&key=${this.getAuthData.idTokenParsed.inedKey}`);
          return `${arg}?auth=login&key=${this.getAuthData.idTokenParsed.inedKey}`;
        } else {
          return arg;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .file-move-btn
    height: 26px !important
    padding: 0 8px !important
    margin-left: 15px !important
    cursor: url(../../assets/icons/cursor.svg), auto
</style>
