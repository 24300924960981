<template>
  <div>
    <vue-ckeditor
      v-if="!loading"
      v-model="editData"
      :editor="editor"
      :config="editorConfig"
      @ready="onEditorReady"
    />
    <div v-else v-text="'Loading'"/>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor.js';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor.js';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import HorizontalLine from '@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice.js';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat.js';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough.js';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline.js';

export default {
  name: "vue-editor",
  components: {
    "vue-ckeditor": CKEditor.component,
  },
  props: {
    editDataProp: {
      type: String,
      default: "0",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    editData: {
      get() {
        return this.editDataProp;
      },
      set(text) {
        this.$emit("update:edit-data-prop", text);
      },
    },
  },
  data() {
    return {
      newDataForEditor: "",
      flagReady: false,
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Autoformat,
          Alignment,
          BlockQuote,
          Bold,
          Essentials,
          FontBackgroundColor,
          FontColor,
          Heading,
          HorizontalLine,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          Strikethrough,
          Table,
          TableToolbar,
          TextTransformation,
          Underline
        ],
        toolbar: {
          items: [
            // 'heading',
            // '|',
            'bold',
            'italic',
            'underline',
            'alignment',
            // 'strikethrough',
            '|',
            'fontBackgroundColor',
            'fontColor',
            'removeFormat',
            '|',
            'horizontalLine',
            'blockQuote',
            'bulletedList',
            'numberedList',
            'insertTable',
            '|',
            'link',
            'imageUpload',
            'mediaEmbed',
            '|',
            'undo',
            'redo'
          ]
        },
        language: "ru",
        link: {
          decorators: {
            // toggleDownloadable: {
            //   mode: "manual",
            //   label: "Dialogs",
            //   attributes: {
            //     click: "dialogs[0].showDialog = true",
            //   },
            // },
            openInNewTab: {
              mode: "manual",
              label: "Открыть в новом окне",
              defaultValue: false, // This option will be selected by default.
              attributes: {
                target: "_blank",
              },
            },
          },
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  methods: {
    // onEditorReady: function () {
    //   this.newDataForEditor = this.editDataProp;
    //   this.flagReady = true;
    // },
    onEditorReady(editor) {
      editor.model.change(writer => {
        writer.setSelection(editor.model.document.getRoot(), 'end');
      });
      editor.editing.view.focus();
    }
  },
};
</script>

<style scoped>
</style>