<template>
  <div style="display: flex; flex-direction: column">
    <div v-if="loading" style="min-height: 200px" class="d-flex flex-row align-center">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-if="!loading">
      <div v-if="isManager" style="display: flex; flex-direction: row">
        <button
          :class="
            localFilter === ''
              ? 'usual-btn usual-btn_news usual-btn_selected'
              : 'usual-btn usual-btn_news'
          "
          @click="newsFilter('')">
          Все новости
        </button>
        <button
          :class="
            localFilter === 'VUZ'
              ? 'usual-btn usual-btn_news usual-btn_selected'
              : 'usual-btn usual-btn_news'
          "
          @click="newsFilter('VUZ')">
          Новости для ВУЗов
        </button>
        <button
          :class="
            localFilter === 'FOIV'
              ? 'usual-btn usual-btn_news usual-btn_selected'
              : 'usual-btn usual-btn_news'
          "
          @click="newsFilter('FOIV')">
          Новости для ФОИВ
        </button>
        <button
          class="usual-btn usual-btn_news"
          style="margin-left: auto"
          @click="textCreation = !textCreation">
          Добавить новость
        </button>
      </div>
      <NewsItem
        v-for="(item, j) in filteredNewsArray"
        :key="j"
        style="display: flex; flex-direction: column"
        :value.sync="item"
        :founderSpr="founderSpr"
        @getAll="getAll" />
    </div>
    <div v-if="textCreation">
      <div class="zxczxc__overlay"></div>
      <div class="zxczxc">
        <v-card class="dialog">
          <img
            @click="textCreation = false"
            class="dialog__exit"
            src="@/assets/icons/dialog-exit.png"
            alt="exit" />
          <h2 class="dialog__header">Добавление новости</h2>
          <div class="dialog__line"></div>
          <div class="dialog__div">
            <label class="dialog__label">Заголовок новости</label>
            <v-text-field v-model="newsItem.header" required></v-text-field>
            <label class="dialog__label">Ссылка заголовка</label>
            <v-text-field v-model="newsItem.url" required></v-text-field>
            <div style="display: flex; flex-direction: row">
              <div class="dialog__type-input">
                <label class="dialog__label">Тип</label>
                <v-autocomplete
                  clearable
                  v-model="newsItem.type"
                  :items="newsTypes"></v-autocomplete>
              </div>
              <div class="dialog__date-input">
                <label class="dialog__label">Дата</label>
                <vue-ctk-date-time-picker
                  id="DateTimePicker"
                  v-model="newsItem.date"
                  :no-button-now="true"
                  only-date
                  format="YYYY-MM-DD"
                  formatted="LL"
                  label=""
                  class="dialog__date" />
              </div>
              <v-checkbox
                off-icon
                v-model="newsItem.show"
                label="Показывать новость"
                style="margin-right: 43px"></v-checkbox>
              <v-checkbox off-icon v-model="newsItem.fix" label="Закрепить новость"></v-checkbox>
            </div>
            <div>
              <label class="dialog__label">Текст новости</label>
              <CkEditor class="dialog__text" :edit-data-prop.sync="newsItem.text" />
            </div>
            <v-autocomplete
              v-model="orgFounders"
              :items="founderSpr"
              multiple
              label="Указать для кого предназначена новость"></v-autocomplete>
            <div class="dialog__files">
              <label class="dialog__label">Приложенные файлы</label>
              <div
                style="display: flex; flex-direction: row; margin-right: 70px"
                v-for="(file, y) in newsItem.filesToUpload"
                :key="y">
                <v-text-field
                  style="width: 500px; margin-right: 15px"
                  v-model="file.fileTitle"
                  required></v-text-field>
                <v-file-input
                  style="width: 50px"
                  prepend-icon="mdi-folder"
                  v-model="file.fileObject"
                  accept="application/pdf, .doc, .docx, .xlsx, .xls"
                  placeholder="Выберите файл">
                </v-file-input>
              </div>
              <img
                src="@/assets/icons/add-file-round-blue.svg"
                class="dialog__files-add-input"
                alt="add-file"
                @click="addFileInput" />
              <!--            <input type="file">-->
            </div>
          </div>
          <div class="dialog__line"></div>
          <v-card-actions>
            <button class="dialog-btn" @click="addText">Сохранить</button>
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <!--    <v-dialog-->
    <!--      v-model="textCreation"-->
    <!--      persistent-->
    <!--      max-width="1250px"-->
    <!--    >-->
    <!--    </v-dialog>-->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import CkEditor from '@/components/UI/vue-ckeditor';
  import axios from 'axios';
  import NewsItem from '@/layouts/newsCMS/NewsItem';
  import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

  export default {
    name: 'NewsForOrg',
    components: { CkEditor, NewsItem, VueCtkDateTimePicker },
    data() {
      return {
        activated: false,
        loading: true,
        textCreation: false,
        founderSpr: [],
        newsArray: [],
        filteredNewsArray: [],
        localFilter: '',
        newsItem: {
          text: '',
          date: '',
          url: '',
          header: '',
          type: '',
          show: true,
          fix: false,
          fileTitle: '',
          filesToUpload: [{}],
        },
        orgFounders: [],
        newsTypes: [
          { text: 'Новости для ВУЗов', value: 'VUZ' },
          { text: 'Новости для ФОИВов', value: 'FOIV' },
          { text: 'Новости для ВУЗов и ФОИВов', value: 'ALL' },
        ],
      };
    },
    created() {
      if (this.role === 'admin') {
        this.getAll();
      } else if (this.role === 'foiv') {
        this.getAllFoiv();
      } else {
        this.getAllOrg();
      }

      this.getFounderSpr();
    },
    props: {
      filter: {
        type: String,
        default: '',
      },
      role: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
        isManager: 'isManager',
      }),
    },
    // watch: {
    //   textCreation(val) {
    //     setTimeout(() => {
    //       this.activated = val;
    //     }, 300);
    //   },
    // },
    methods: {
      isSelected(filter) {
        return this.filter === filter;
      },
      async getFounderSpr() {
        try {
          const res = await axios.get(`/api/cms/spr/founder/get`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.founderSpr.push({
                ...res.data[i],
                text: res.data[i].name,
                value: res.data[i].id,
              });
            }
            console.log('getFounderSpr успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getAll() {
        this.loading = true;
        try {
          // const res = await axios.get(`/api/cms/getAllByPage?page=0&pagable=true`,
          const res = await axios.get(`/api/cms/getAll`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.newsArray = res.data;
            this.filteredNewsArray = res.data;
            this.newsFilter(this.filter);
            console.log('getAll успех');
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },

      async getAllFoiv() {
        this.loading = true;
        let ID = '';
        if (this.getUserRoles.includes('FOUNDER_ROLE')) {
          ID = this.getAuthData.tokenParsed.founderID;
        } else if (this.getUserRoles.includes('REGION_ROLE')) {
          ID = this.getAuthData.tokenParsed.regionID;
        }

        try {
          const res = await axios.get(`/api/cms/byFounderId/get?founderId=${ID}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.newsArray = res.data;
            this.filteredNewsArray = res.data;
            this.newsFilter(this.filter);
            console.log('getAllFoiv успех');
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },

      async getAllOrg() {
        this.loading = true;
        try {
          const res = await axios.get(
            `/api/cms/byOrgId/get?orgId=${this.getAuthData.tokenParsed.orgID}`,
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            this.newsArray = res.data;
            this.filteredNewsArray = res.data;
            this.newsFilter(this.filter);
            console.log('getAllOrg успех');
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },

      async addText() {
        this.loading = true;
        const founderArray = [];
        if (this.orgFounders.length > 0) {
          for (let y = 0; y < this.orgFounders.length; y++) {
            founderArray.push(this.founderSpr.filter((el) => el.id === this.orgFounders[y])[0]);
          }
        }
        try {
          const res = await axios.post(
            `/api/cms/set`,
            {
              header: this.newsItem.header,
              text: this.newsItem.text,
              fix: this.newsItem.fix,
              show: this.newsItem.show,
              type: this.newsItem.type,
              date: this.newsItem.date,
              stpUrl: this.newsItem.url,
              orgFounders: founderArray,
            },
            { headers: { Authorization: `Bearer   ${this.getAuthData.token}` } },
          );
          if (res.status === 200 && res.data) {
            if (this.newsItem.filesToUpload[0].fileObject) {
              for (let x = 0; x < this.newsItem.filesToUpload.length; x++) {
                await this.uploadFile(
                  res.data.id,
                  this.newsItem.filesToUpload[x].fileObject,
                  this.newsItem.filesToUpload[x].fileTitle,
                );
              }
            }
            await this.getAll();
            this.textCreation = false;
            this.newsItem.text = '';
            this.newsItem.header = '';
            this.newsItem.fix = false;
            this.newsItem.show = false;
            this.newsItem.type = '';
            this.newsItem.url = '';
            this.newsItem.date = '';
            this.newsItem.filesToUpload = [{}];
            console.log('addText успех');
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },

      async uploadFile(arg, file, fileTitle) {
        console.log(fileTitle);
        const data = new FormData();
        data.append('file', file);
        if (fileTitle === undefined) {
          data.append('fileTitle ', '');
        } else {
          data.append('fileTitle ', fileTitle);
        }
        data.append('scanType', this.newsItem.type);
        data.append('articleId', arg);
        const responseAxios = await axios.put('/api/cms/file/scan/upload_admin_articleId', data, {
          headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
        });
        if (responseAxios.status === 200) {
          console.log('Файл успешно загружен');
        } else {
          console.log('Ошибка загрузки файла');
        }
      },

      // newsFilter(arg) {
      //   if (arg === '') {
      //     this.filter = '';
      //     this.filteredNewsArray = this.newsArray;
      //   } else if (arg === 'VUZ') {
      //     this.filter = 'VUZ';
      //     this.filteredNewsArray = this.newsArray.filter(
      //       (el) => el.type === 'VUZ' || el.type === 'ALL',
      //     );
      //   } else if (arg === 'FOIV') {
      //     this.filter = 'FOIV';
      //     this.filteredNewsArray = this.newsArray.filter(
      //       (el) => el.type === 'FOIV' || el.type === 'ALL',
      //     );
      //   }
      // },
      newsFilter(filter) {
        if (filter === '') {
          this.filteredNewsArray = this.newsArray.map((item) => ({ ...item }));
        } else {
          this.filteredNewsArray = this.newsArray.filter(
            (item) => item.type === filter || item.type === 'ALL',
          );
          this.localFilter = '';
        }
        this.localFilter = filter;
      },

      addFileInput() {
        this.newsItem.filesToUpload.push({});
      },
    },
  };
</script>

<style lang="scss" scoped></style>
