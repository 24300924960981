<template>
  <div>
    <NewsForOrg
      v-if="isManager"
      :filter="''"
      :role="'admin'"
    />
    <NewsForOrg
      v-else-if="isFounder || isRegion"
      :filter="'FOIV'"
      :role="'foiv'"
    />
    <NewsForOrg
      v-else-if="isOrg"
      :filter="'VUZ'"
      :role="'vuz'"
    />
    <NewsForOrg
      v-else-if="isExpert"
      :filter="''"
      :role="'admin'"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import NewsForOrg from './NewsForOrg';

export default {
  name: "News",
  components: {NewsForOrg},
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      isManager: 'isManager',
      isOrg: 'isOrg',
      isRegion: 'isRegion',
      isFounder: 'isFounder',
      isExpert: 'isExpert'
    }),
  }
}
</script>

<style scoped>

</style>