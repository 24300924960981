<template>
  <div @mouseout="hover = false" @mouseover="hover = true"
       @click="getFile(value.id, value.fileNameOrig, value.fileExt)">
    <img v-if="hover" src="@/assets/icons/download-file-hover.svg" alt="download-file">
    <img v-else src="@/assets/icons/download-file.svg" alt="download-file">
    <p class="newsItem__file-name">{{ value.fileTitle === "undefined" ? value.fileNameOrig : value.fileTitle }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters} from "vuex";

export default {
  name: "NewsFile",
  props: {
    value: {
      type: [Object, Array],
      default: function () {
        return []
      },
    },
  },
  computed: {
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    async getFile(id, name, ext) {
      let uri = `/api/cms/file/scan/download?file=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`}
      })
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext)
    },
  
    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },
  }
}
</script>

<style scoped>

</style>